import { useRef } from 'react'
import Canvas from './canvas'
import Sidebar from './sidebar'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'
import './style.scss'
const Index = ({
   listing,
    getListing,
    getAllImages,
    setOpenPhotoEditModal
}) => {
    const mainBodyRef = useRef(null)
    return (
        <KTCard className='card-wrapper'>
            <div className="d-flex" style={{height: '100%'}}>
                <Sidebar listing={listing} getListing={getListing} getAllImages={getAllImages} setOpenPhotoEditModal={setOpenPhotoEditModal} mainBodyRef={mainBodyRef}  />
                 {mainBodyRef && <Canvas mainBodyRef={mainBodyRef} /> }
            </div>
        </KTCard>
    )
}

export default Index

