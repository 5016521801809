import { KTIcon } from "../../../../_metronic/helpers";
import { formatPrice } from "../../../../_metronic/helpers/utils";

const PointsModalHeader = ({
  user,
  setOpenPointsModal,
  setOpenTopUpWalletModal,
  haveAccess,
  currentUser,
  showClubCash,
  setShowClubCash
}) => {
  return (
    <div className="modal-header flex-column pb-0">
      <div className="d-flex w-100 justify-content-between align-items-center">
        <div>
          <h2 className="fw-bolder mb-3">{`Cash Wallet of ${user?.first_name} ${user?.last_name}`}</h2>
          <h3>Available Funds: <span className="fw-bolder text-success">{user?.points ? formatPrice(user?.points) : '$0'}</span></h3>
          {(user?.is_b2b && (user?.org_role == 1 || user?.org_role == 2)) && <h5>Total Funds (Organization): <span className="fw-bolder text-success">{user?.balance ? formatPrice(user?.balance) : '$0'}</span></h5>}
        </div>
        <div className="d-flex flex-column align-items-end">
          <button
            className="btn btn-icon btn-sm btn-active-icon-primary"
            data-kt-users-modal-action="close"
            onClick={() => {
              setOpenPointsModal(false);
            }}
            style={{ cursor: "pointer" }}
          >
            <KTIcon iconName="cross" className="fs-1" />
          </button>
          {haveAccess(currentUser, 'wallet_balance') && (user?.is_b2b && user?.org_role == 1 || !user?.is_b2b) && <div className="d-flex gap-2">
            <button className="btn btn-sm btn-success"
              onClick={() => setOpenTopUpWalletModal({
                status: true,
                type: 'add'
              })}
            >
              <KTIcon iconName="dollar" className="fs-1" /> Add Funds to Wallet
            </button>
            {user?.points > 0 && (user?.is_b2b && user?.org_role == 1 || !user?.is_b2b) && <button className="btn btn-sm btn-danger"
              onClick={() => setOpenTopUpWalletModal({
                status: true,
                type: 'remove'
              })}
            >
              <KTIcon iconName="minus" className="fs-1" /> Remove Funds from Wallet
            </button>
            }
          </div>
          }
        </div>
      </div>
      <div className="d-flex justify-content-end w-100 mt-2 mb-5">
        <div>
          <input
            className="form-check-input my-0 mx-2"
            type="checkbox"
            id="clubCash"
            value={showClubCash}
            checked={showClubCash}
            onChange={() => setShowClubCash(!showClubCash)}
          />
          <label
            className="form-check-label"
            htmlFor="clubCash"
            style={{ fontWeight: "700", color: "#3f4254" }}
          >
            Show only club cash entries
          </label>
        </div>
      </div>
    </div>
  );
};

export { PointsModalHeader };
