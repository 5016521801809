import { useEffect } from "react";
import ImageEdit from "../../../ImageEditor";
import { KTIcon } from "../../../../../_metronic/helpers";
import { useCanvas } from "../../../ImageEditor/context/canvasContext";

const PhotoEditModal = ({listing, getListing, getAllImages, setOpenPhotoEditModal }) => {
  const { setImagePreview, canvasRef, setCanvasLoading, canvasLoading, setSelectedShape } = useCanvas()
  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className="modal-dialog modal-dialog-centered position-relative photo-edit-modal" style={{ maxWidth: '1023px', minHeight: '100vh', margin: '0 auto'}}>
        <div className="modal-content" style={{ height: '635px', maxHeight: '660px'}}>
        <button
        className="btn btn-icon btn-active-icon-primary position-absolute top-0"
        data-kt-users-modal-action="close"
        onClick={() => {
          setOpenPhotoEditModal(false)
          setImagePreview(null)
          setSelectedShape(null)
          setCanvasLoading(false)
          canvasRef.current = null
        }}
        style={{ cursor: "pointer", right: 0, zIndex: 1 }}
      >
        <KTIcon iconName="cross" className="text-danger text-hover-dark fs-1"/>
      </button>
        <ImageEdit 
          listing={listing}
          getListing={getListing}
          getAllImages={getAllImages}
          setOpenPhotoEditModal={setOpenPhotoEditModal}
          canvasLoading={canvasLoading}
          setCanvasLoading={setCanvasLoading}
        />
        </div>
        </div>
      </div>
      {/* begin::Modal Backdrop */}
      <div className="modal-backdrop fade show"></div>
      {/* end::Modal Backdrop */}
    </>
  );
};

export { PhotoEditModal };
