export const colors = [
  '#020000', '#ff403d', '#32cc71', '#808080', '#ffb800', '#ffa459', '#00a6ff', '#ffffff'
]

export const strokes = [
  '#000000', '#ff403d', '#32cc71', '#808080', '#ffb800', '#ffa459', '#00a6ff', '#ffffff'
]
export const hexToRgba = (hex, alpha) => {
  let r = 0, g = 0, b = 0;

  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }

  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const rgbToHex = (r, g, b) => {
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  const hex = "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase();
  return hex;
}

export const rgbaToHex = (rgba) => {
  const regex = /rgba\((\d+), (\d+), (\d+), (\d+(\.\d+)?)\)/;
  const match = rgba.match(regex);

  if (match) {
  const r = parseInt(match[1]);
  const g = parseInt(match[2]);
  const b = parseInt(match[3]);
  const a = parseFloat(match[4]);

  const rHex = r.toString(16).padStart(2, '0');
  const gHex = g.toString(16).padStart(2, '0');
  const bHex = b.toString(16).padStart(2, '0');

  return `#${rHex}${gHex}${bHex}`;
  } else {
    return '#ffffff';
  }
}

